import { useRef, useState } from 'react';
import Link from 'next/link';
import { rgba } from 'polished';
import styled from 'styled-components';

import ActiveLink from '@/components/ActiveLink';

interface Props {
  items: SanityNavigation[];
  className?: string;
}

interface CollapsibleProps {
  open: boolean;
}

interface ItemProps {
  open?: boolean;
}

const Wrap = styled.nav`
  height: ${props => props.theme.helpers.toRem(60)};
  padding: 0 ${props => props.theme.helpers.toRem(47)} 0 0;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 0 ${props => props.theme.helpers.toRem(20)} ${props => props.theme.helpers.toRem(20)} 0;
  background: ${props => rgba(props.theme.colors.mainDark, 0.7)};

  @media screen and (max-width: 1200px) {
    height: auto;
    padding: 0 0 0 20px;
    position: absolute;
    top: 30px;
    left: 0;
    align-items: flex-start;
    overflow: hidden;
    z-index: 1;
  }

  &:before {
    content: '';
    height: 100%;
    width: 9999px;
    position: absolute;
    top: 0;
    right: 100%;
    background: ${props => rgba(props.theme.colors.mainDark, 0.7)};
  }
`;

const Hamburger = styled.button`
  height: ${props => props.theme.helpers.toRem(38)};
  margin-right: ${props => props.theme.helpers.toRem(26)};
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  &:hover {
    span {
      background: ${props => props.theme.colors.secondary};
    }
  }

  @media screen and (max-width: 1200px) {
    margin: 10px 26px 10px 0;
  }

  span {
    height: ${props => props.theme.helpers.toRem(4)};
    margin: ${props => props.theme.helpers.toRem(4)} 0;
    display: block;
    background: ${props => props.theme.colors.main};
    transition: all 0.2s;

    &:nth-child(1) {
      width: ${props => props.theme.helpers.toRem(38)};
    }

    &:nth-child(2) {
      width: ${props => props.theme.helpers.toRem(25)};
    }

    &:nth-child(3) {
      width: ${props => props.theme.helpers.toRem(36)};
    }
  }
`;

const Collapsible = styled.div<CollapsibleProps>`
  max-width: ${props => props.open ? '100vw' : 0};
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: max-width 0.3s ease-in-out, max-height 0.3s ease-in-out;

  @media screen and (max-width: 1200px) {
    max-height: ${props => props.open ? '100vw' : 0};
    align-items: flex-start;
  }
`;

const Close = styled.button`
  height: ${props => props.theme.helpers.toRem(38)};
  padding: 0 ${props => props.theme.helpers.toRem(26)};
  border: 0;
  background: transparent;
  cursor: pointer;
  transform: rotate(180deg);

  @media screen and (max-width: 1200px) {
    display: none;
  }

  &:hover {
    span {
      background: ${props => props.theme.colors.secondary};
    }
  }

  span {
    height: ${props => props.theme.helpers.toRem(2)};
    display: block;
    background: ${props => props.theme.colors.main};
    transform-origin: ${props => props.theme.helpers.toRem(3)};
    transition: all 0.2s;

    &:nth-child(1) {
      width: ${props => props.theme.helpers.toRem(9)};
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      width: ${props => props.theme.helpers.toRem(7)};
      transform: rotate(-45deg);
    }
  }
`;

const Items = styled.ul`
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;

  @media screen and (max-width: 1200px) {
    align-items: stretch;
    flex-direction: column;
  }
`;

const Item = styled.li<ItemProps>`
  & > a,
  & > button {
    padding: ${props => props.theme.helpers.toRem(16)} ${props => props.theme.helpers.toRem(25)};
    border: 0;
    display: flex;
    align-items: center;
    font-family: inherit;
    ${props => props.theme.helpers.fontSize(24)};
    font-weight: inherit;
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    color: ${props => props.theme.colors.main};
    transition: all 0.2s;

    @media screen and (max-width: 1200px) {
      width: 100%;
      padding: ${props => props.theme.helpers.toRem(16)} ${props => props.theme.helpers.toRem(20)};
      border-top: 1px solid ${props => rgba(props.theme.colors.mainDark, 0.1)};
      ${props => props.theme.helpers.fontSize(18)};
      justify-content: space-between;
    }

    &:not(:first-child) {
      margin-left: ${props => props.theme.helpers.toRem(20)};
    }

    &.active,
    &:hover {
      background: ${props => rgba(props.theme.colors.secondary, 0.64)};
      color: ${props => props.theme.colors.main};
      outline: none;
    }

    svg {
      display: block;
      transform: rotate(${props => props.open ? -180 : 0}deg);
      transition: transform 0.2s;

      &:first-child {
        margin-right: ${props => props.theme.helpers.toRem(8)};
      }

      &:last-child {
        margin-left: ${props => props.theme.helpers.toRem(8)};
      }

      path {
        fill: currentColor;
      }
    }
  }
`;

const Menu = ({ items, className }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const getPagePrefix = (type: string) => {
    switch (type) {
      case 'blogPost':
        return '/blog';

      default:
        return '';
    }
  };

  return (
    <Wrap className={className} data-aos="fade-right" data-aos-delay="200">
      <Hamburger aria-label="Abrir menu" onClick={() => setOpen(open => !open)}>
        <span></span>
        <span></span>
        <span></span>
      </Hamburger>

      <Collapsible open={open}>
        <Close aria-label="Fechar menu" onClick={() => setOpen(false)}>
          <span></span>
          <span></span>
        </Close>

        <Items>
          {items.map(item => (
            <Item key={`menu-${item._id}`}>
              {item.external ? (
                <Link
                  href={item.externalUrl as string}
                  onClick={() => setOpen(false)}>

                  {item.title}
                </Link>
              ) : (
                <ActiveLink
                  href={`${getPagePrefix(item.internalPage?._type as string)}/${item.internalPage?.slug.current}`}
                  onClick={() => setOpen(false)}>

                  <a>{item.title}</a>
                </ActiveLink>
              )}
            </Item>
          ))}
        </Items>
      </Collapsible>
    </Wrap>
  );
};

export default Menu;
