import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import logo from '@root/public/img/logo.png';

import Container from '@/components/Layout/Container';

import Menu from './Menu';

interface Props {
  navigation: SanityNavigation[];
}

const SiteHeader = styled.header`
  margin-top: ${props => props.theme.helpers.toRem(38)};
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    margin-top: 10px;
  }

  ${Container} {
    display: flex;
    align-items: center;
  }
`;

const Logo = styled.div`
  margin: 0 ${props => props.theme.helpers.toRem(-35)} 0 auto;

  img {
    width: ${props => props.theme.helpers.toRem(356)};
    height: ${props => props.theme.helpers.toRem(156)};
    vertical-align: top;

    @media screen and (max-width: 1200px) {
      width: auto;
      height: 100px;
    }
  }
`;

const Header = ({ navigation }: Props): JSX.Element => {
  return (
    <SiteHeader>
      <Container>
        <Menu items={navigation} />

        <Logo data-aos="fade-down" data-aos-delay="200">
          <Link href="/">
            <Image
              width={356}
              height={156}
              src={logo}
              alt="Logotipo" />
          </Link>
        </Logo>
      </Container>
    </SiteHeader>
  );
};

export default Header;
